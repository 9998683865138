import React, { Component } from 'react'
import Kenneth from '../img/accom-1.jpg'
import Layout from '../layouts/index'
import Helmet from 'react-helmet'
import ReactModal from 'react-modal'
import NetlifyForm from 'react-netlify-form'
import Footer from '../components/footer'
class About extends Component {
  state = {
    modalState: false,
  }

  componentDidMount() {
    ReactModal.setAppElement('body')
  }

  toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalState

      return { modalState: newState }
    })
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>About Kenneth Au Brisbane</title>
          <meta
            name="description"
            content="I am Kenneth Au from Brisbane, I was an Analyst with experience in
                  banking, corporate treasury, PPP infrastructure projects. Now, I am a software engineer with commercial experiences in
                  building web application with React and Redux."
          />
        </Helmet>
        <section className="section section-about">
          <div className="columns">
            <div className="column">
              <img className="" alt="background" src={Kenneth} />
            </div>

            <div className="column about-me">
              <h1 className="title is-1">Kenneth Au</h1>
              {/* <ul className="social-list list-inline list--hover">
              <li>
                <a href="#">
                  <i className="socicon socicon-twitter icon icon--xs" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="socicon socicon-linkedin icon icon--xs" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="socicon socicon-medium icon icon--xs" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="socicon socicon-instagram icon icon--xs" />
                </a>
              </li>
            </ul> */}
              <p className="subtitle is-4">
                I am a Software engineer with commercial experiences in building
                web application with React and Redux. I have worked with
                technologies such as React, Redux, React Native, Node, AWS
                Lambda.
                <br />
                <br />
                Looking for an opportunity to join an established professional
                team which can help me further develop my skills and enable me
                to help their business and be involved in innovative and
                exciting projects.
                <br />
                <br />
                In my previous career path I was an Analyst with experience in
                banking, corporate treasury, PPP infrastructure projects. I have
                direct working experience in financial modeling, project
                finance, treasury risk management (FX & commodity) and corporate
                cash flow forecast.
              </p>
              <div className="columns buttons">
                <div className="column is-3">
                  <a
                    className="button is-dark is-medium is-outlined  is-rounded is-hovered"
                    href="#"
                  >
                    <span className="btn__text">My Resume</span>
                  </a>
                </div>
                <div className="column is-2">
                  <a
                    className="button is-dark is-medium is-outlined  is-rounded is-hovered"
                    onClick={this.toggleModal}
                  >
                    <span className="btn__text">Get In Touch</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ReactModal
          isOpen={this.state.modalState}
          onRequestClose={this.toggleModal}
          contentLabel="Example Modal In Gatsby"
        >
          {/* <form
            name="contact-form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input name="name" placeholder="Your Name" type="text" />
            <input name="email" placeholder="name@name.com" type="email" />
            <textarea name="message" />
            <button>Send</button>
          </form> */}
          coming soon.
        </ReactModal>
        <Footer />
      </Layout>
    )
  }
}

// const Modal = ({ children, closeModal, modalState, title }) => {
//   if (!modalState) {
//     return null
//   }

//   return (

//   )
// }

export default About
// <div className="field">
//   <label className="label">Name</label>
//   <div className="control">
//     <input className="input" type="text" placeholder="Text input"/>
//   </div>
// </div>

// <div className="field">
//   <label className="label">Email</label>
//   <div className="control has-icons-left has-icons-right">
//     <input className="input is-danger" type="email" placeholder="Email input" value="Your email address"/>
//     <span className="icon is-small is-left">
//       <i className="fas fa-envelope"></i>
//     </span>
//     <span className="icon is-small is-right">
//       <i className="fas fa-exclamation-triangle"></i>
//     </span>
//   </div>
//   <p className="help is-danger">This email is invalid</p>
// </div>

// <div className="field">
//   <label className="label">Message</label>
//   <div className="control">
//     <textarea className="textarea" placeholder="Textarea"></textarea>
//   </div>
// </div>

// <div className="field is-grouped">
//   <div className="control">
//     <button className="button is-link">Submit</button>
//   </div>
//   <div className="control">
//     <button className="button is-text">Cancel</button>
//   </div>
// </div>

// <div className="modal is-active">
// <div
//   className="modal-background"
//   onClick={this.toggleModal}
// />
// <form
//   action="https://getform.io/f/38aef5d5-4736-43c0-9550-95c7473a4f2f"
//   method="POST"
// >
//   <div className="modal-card">
//     <header className="modal-card-head">
//       <p className="modal-card-title">Here</p>
//       <button
//         className="delete"
//         onClick={this.toggleModal}
//       />
//     </header>
//     <section className="modal-card-body">
//       <div className="content">
//         <div className="field">
//           <label className="label">Name</label>
//           <div className="control">
//             <input
//               className="input"
//               type="text"
//               placeholder="Text input"
//               value={this.state.name}
//               onChange={this.handleNameChange}
//               name="name"
//             />
//           </div>
//         </div>
//         <div className="field">
//           <label className="label">Email</label>
//           <div className="control has-icons-left has-icons-right">
//             <input
//               name="email"
//               className="input"
//               type="email"
//               placeholder="Email input"
//               onChange={this.handleEmailChange}
//             />
//           </div>
//         </div>

//         <div className="field">
//           <label className="label">Message</label>
//           <div className="control">
//             <textarea
//               className="textarea"
//               placeholder="Textarea"
//               onChange={this.handleMessageChange}
//               name="message"
//             />
//           </div>
//         </div>
//       </div>
//     </section>
//     <footer className="modal-card-foot">
//       <div className="field is-grouped">
//         <div className="control">
//           <button type="submit" className="button is-link">
//             Submit
//           </button>
//         </div>
//         <div className="control">
//           <button
//             onClick={this.toggleModal}
//             className="button is-text"
//           >
//             Cancel
//           </button>
//         </div>
//       </div>
//     </footer>
//   </div>
// </form>
// </div>
// </div>
