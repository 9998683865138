import React from 'react'

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <span className="type--fine-print">Brisbane</span>
      <div>
        <span className="type--fine-print">
          &copy;
          <span className="update-year" /> <strong>Kenneth Au</strong> 2019
        </span>
      </div>
    </div>
  </footer>
)

export default Footer
